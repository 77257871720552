@import '../color_variables';

$default-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.31, 1.78);
$toggle-transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.51, 1.3);
$expand-transition: all 0.7s cubic-bezier(0.12, 0.9, 0.58, 1);

$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1030px;
$medium-width-breakpoint: 970px;
$small-width-breakpoint: 750px;
$very-small-width-breakpoint: 490px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$main-background-color: #f8f8f8;
$default-black-color: #404040;
$default-green-color: #00a400;

.main-header {
  background: white;

  &,
  &--expanded {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    // position: sticky;
    top: 0;
    will-change: transform;
    z-index: 5;
    position: fixed;
    width: 100%;
    transition: $expand-transition;
  }

  &--expanded {
    background: transparent;
  }

  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    max-width: $content-max-width;
    // padding: 0.9em 0 0.6em;
    padding: 0.45em 0 0.3em;
    z-index: 5;
  }

  &__logo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    margin: 0 0 0 8px;
    text-decoration: none;
    cursor: pointer;
    transition: $expand-transition;
    position: relative;
    top: 0;
    left: 0;

    @media (max-width: $very-small-width-breakpoint) {
      margin: 0 0 0 4px;
    }

    &__image {
      max-width: 100%;
      max-height: 5em;
      transition: $expand-transition;

      @media (max-width: $high-width-breakpoint) {
        max-height: 3.5em;
      }
      @media (max-width: 870px) {
        width: 3.5em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        max-height: 3em;
        width: 3em;
      }

      // @media (max-width: $medium-width-breakpoint) {
      //   display: none;
      // }
    }

    &__text-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      transition: $expand-transition;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.65em;
      }
    }

    &__text,
    &__subtext {
      text-transform: uppercase;
      font-size: 1.8em;
      font-weight: bold;
      white-space: nowrap;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.9em;
      }

      // @media (max-width: $medium-width-breakpoint) {
      //   margin: 0 0 0 0.1em;
      // }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.5em;
      }
    }

    &__text {
      line-height: 1em;
      color: $main-background-color;
      text-shadow: 1px 0 0 $default-black-color, -1px 0 0 $default-black-color, 0 1px 0 $default-black-color, 0 -1px 0 $default-black-color, 1px 1px $default-black-color, -1px -1px 0 $default-black-color, 1px -1px 0 $default-black-color, -1px 1px 0 $default-black-color;
    }

    &__subtext {
      font-size: 1.7em;
      color: $default-green-color;
      font-weight: 900;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.79em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.7em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.42em;
      }
    }
  }

  &--expanded &__logo {
    top: 1.2em;

    @media (max-width: $extra-high-width-breakpoint) {
      left: 2.5em;
    }
    @media (max-width: 1080px) {
      left: 4em;
    }
    @media (max-width: $medium-width-breakpoint) {
      left: 1em;
      top: 0.8em;
    }
    @media (max-width: 870px) {
      left: 0;
      top: 0;
    }
  }
  &--expanded &__logo__image {
    font-size: 1.7em;

    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: 1200px) {
      font-size: 1.5em;
    }
    @media (max-width: 1080px) {
      font-size: 1.4em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: 870px) {
      font-size: 1em;
    }
  }
  &--expanded &__logo__text-container {
    font-size: 1em;

    @media (max-width: $high-width-breakpoint) {
      font-size: 0.95em;
    }
    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.8em;
    }
    @media (max-width: 870px) {
      font-size: 0.65em;
    }
  }

  &__controls-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 20px 0 0;

    // @media (max-width: $medium-width-breakpoint) {
    //   flex-flow: column nowrap;
    //   align-items: flex-end;
    //   margin: 0;
    // }

    @media (max-width: $small-width-breakpoint) {
      flex-flow: row nowrap;
      align-items: center;
      z-index: 5;
    }
  }

  &__navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    color: $gray-text-color;

    // @media (max-width: $medium-width-breakpoint) {
    //   order: 2;
    // }

    @media (max-width: $small-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      padding: 4.5em 0 0;
      background: white;
      height: 100%;
      z-index: 4;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 4em 0 0;
    }

    @media (max-width: $tiny-width-breakpoint) {
      padding: 3.6em 0 0;
    }

    &__links-container,
    &__social-links-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
    }

    &__links-container {
      // @media (max-width: $medium-width-breakpoint) {
      //   margin: 0 0.8em 0 0;
      // }

      @media (max-width: $small-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        margin: 0;
      }
    }

    &__social-links-container {
      margin: 0 0.4em 0 1em;
      padding: 0.3em 0 0.3em 1em;
      border: 0 solid $gray-text-color;
      border-width: 0 0 0 1px;

      // @media (max-width: $medium-width-breakpoint) {
      //   border-width: 0 0 1px 0;
      //   padding: 0 0.8em 0.8em 2em;
      //   margin: 0 0 0.4em 0;
      // }

      @media (max-width: $small-width-breakpoint) {
        border-width: 0;
        margin: 0 0.5em 0 0;
        padding: 0 0.8em 0 0;
      }
    }

    &__link-wrapper {
      &::after {
        display: block;
        position: relative;
        content: '';
        background: $default-green-color;
        bottom: 0;
        height: 2px;
        width: 0;
        transition: $default-transition;
      }

      &:hover::after {
        width: 80%;
      }

      &,
      &--selected {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        text-align: center;

        &:not(:last-child) {
          margin: 0 1em 0 0;
        }

        @media (max-width: $small-width-breakpoint) {
          align-items: flex-end;

          &:not(:last-child) {
            margin: 0;
          }
        }
      }

      &--selected {
        padding: 0 0 2px;
      }
    }

    &__link {
      transition: $default-transition;
      will-change: transform;
      color: inherit;
      cursor: pointer;

      &,
      &--selected {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 0.95em;
        text-transform: uppercase;
        font-weight: 500;
        text-decoration: none;
        padding: 0.5em 0;
        margin: 2px 0 0;
        min-width: 35px;
        min-height: 35px;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        text-decoration: none;
        background: none;

        @media (max-width: $high-width-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $small-width-breakpoint) {
          padding: 0 0.8em 0 0;
        }
      }

      &--selected {
        color: $default-green-color;
        cursor: default;
        font-weight: 800;
      }
    }

    &__link-wrapper:hover &__link {
      transform: translateY(-0.3em);
      color: $default-black-color;
    }

    &__link-wrapper button.main-header__navigation__link:active {
      color: $default-green-color;
    }

    &__social-link-wrapper {
      &:not(:last-child) {
        margin: 0 0.5em 0 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        &:not(:last-child) {
          margin: 0 0.3em 0 0;
        }
      }
    }

    &__social-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      border-radius: 50%;
      background: $gray-text-color;
      color: white;
      width: 1.6em;
      height: 1.6em;
      cursor: pointer;
      text-decoration: none;
      transition: $default-transition;

      // @media (max-width: $medium-width-breakpoint) {
      //   font-size: 1em;
      //   min-width: 35px;
      //   min-height: 35px;
      // }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.2em;
      }
    }

    &__social-link-wrapper:hover &__social-link {
      box-shadow: 0 0 1px 2px $default-green-color;
      background: $default-black-color;
    }
  }

  &__toggle-container {
    transform: translateX(-103%);

    &,
    &--visible {
      position: fixed;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      top: 0;
      left: 0;
      transition: $toggle-transition;
      will-change: transform;
      height: 130vh;
      min-width: 11.4em;
      z-index: 4;

      &::before {
        display: block;
        position: absolute;
        content: '';
        background: white;
        width: 5em;
        height: 100%;
        right: 99%;
        top: 0;
      }
    }

    &--visible {
      transform: translateX(0);
    }
  }

  &__toggle-background-block {
    transform: translateX(-103%);

    &,
    &--visible {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100vw;
      will-change: transform;
      z-index: 3;
      background: #00000073;
      transition: $toggle-transition;
      cursor: default;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
    }

    &--visible {
      transform: translateX(0);
      cursor: pointer;
    }
  }

  &__menu-toggler {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.6em;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: 1px dashed #8f8f8f;
    border-radius: 3px;
    user-select: none;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    color: $gray-text-color;
    background: none; //linear-gradient(108deg, #919191, #f3f3f3, #919191)
    padding: 0.4em;
    margin: 0 0 0 0.4em;
    transition: $default-transition;
    box-shadow: 0 0 2px $gray-text-color

    & i {
      border: none;
    }

    &:hover {
      color: $default-black-color;
      border: 1px dashed $default-black-color;
    }

    &:active {
      color: $default-green-color;
      border: 1px dashed $default-green-color;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.5em;
    }
  }
}
