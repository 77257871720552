@import '../color_variables.scss';
@import '../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;

.thank-you{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__title {
    font-size: 2.2em;
    font-weight: 700;
    // text-transform: uppercase;
    margin: 0 0 0.75em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      margin: 0 0 0.5em;
      align-self: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em;
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__text-container {

    text-align: center;
  }

  // &__centralized-text-wrapper {
  //   display: block;
  //   text-align: center;
  // }
}
