@import '../color_variables.scss';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$medium-height-breakpoint: 650px;

$default-animation-period: 0.6s;
$default-animation-function: cubic-bezier(0.17, 0.67, 0.31, 1.78);

$default-transition: all 0.3s cubic-bezier(0.22, 0.97, 0.58, 1);
$service-transition-period: 0.2s;

$content-max-width: 75em;


$flow-circle-width: 62em;

$main-background-color: #f8f8f8;
$main-background-darker-color: #e6e6e6;
$default-black-color: #404040;
$default-green-color: #00a400;


@keyframes slide-in {
  from {
    opacity: 0;
    transform-origin: -30% center;
    transform: rotate(5deg) translateX(-20%);
  }

  to {
    opacity: 1;
    transform-origin: -30% center;
    transform: rotate(0) translateX(0);
  }
}

@keyframes pop-in {
  from {
    opacity: 0;
    transform: scale(0.8, 0.8) translateY(-2em);
  }

  to {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes glowing-text {
  from {
    text-shadow: 0 0 0px white;
    color: white;
  }

  50% {
    text-shadow: 0 0 0px white;
    color: white;
  }

  51% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  52% {
    text-shadow: 0 0 0px white;
    color: white;
  }

  54% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  55% {
    text-shadow: 0 0 0px white;
    color: white;
  }

  56% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  98% {
    text-shadow: 0 0 2px #f6ff68;
    color: #f6ff68;
  }

  to {
    text-shadow: 0 0 0px white;
    color: white;
  }
}


.main-page {
  // css parallax
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  &__main-banner {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    background: $main-background-color;

    &__images-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }

    &__images-container,
    &__content {
      min-height: 100px;
      height: 44.25em;

      @media (max-width: $extra-high-width-breakpoint) {
        height: 42em;
      }
      @media (max-width: $high-width-breakpoint) {
        height: 39em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        height: 36em;
      }
      @media (max-width: $medium-width-breakpoint) {
        height: 32.7em;
      }
      @media (max-width: $small-width-breakpoint) {
        height: 28em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        height: 23.5em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        height: 23em;
      }
    }

    &__background-detail {
      &--1,
      &--2 {
        display: block;
        position: absolute;
        background: $main-background-darker-color;
        border-radius: 50%;
        transform-origin: center center;
      }

      &--1 {
        // height: 100em;
        // width: 130em;
        height: 225%;
        width: 125%;
        right: 0;
        bottom: 0;
        transform: translate(60%, 57%);
      }
      &--2 {
        // height: 110em;
        // width: 100em;
        height: 245%;
        width: 95%;
        left: 0;
        top: 0;
        transform: translate(-60%, -58%);
      }
    }

    &__decoration-image {
      &--1,
      &--2,
      &--3 {
        display: block;
        position: absolute;
        object-fit: contain;

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 0.82em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 0.75em;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.68em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.72em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.6em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.52em;
        }
      }

      &--1 {
        max-width: 28em;
        margin: 0 0 0 -70em;
        bottom: -6em;

        @media (max-width: $medium-plus-width-breakpoint) {
          bottom: -4em;
        }
        @media (max-width: $medium-width-breakpoint) {
          bottom: -3em;
          margin: 0 0 0 -68em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          bottom: -4em;
          margin: 0 0 0 -26em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          bottom: -2em;
        }
      }

      &--2 {
        max-width: 20em;
        margin: 0 0 0 -14em;
        bottom: 7em;

        @media (max-width: $medium-plus-width-breakpoint) {
          bottom: 8em;
        }
        @media (max-width: $medium-width-breakpoint) {
          bottom: 9em;
          margin: 0 0 0 -15em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          bottom: 4em;
          margin: 0 0 0 24em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          bottom: 7em;
        }
      }

      &--3 {
        max-width: 14.4em;
        margin: 0 0 0 63em;
        bottom: -1em;
        transform-origin: bottom;

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 70em;
        }
        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 65em;
        }
        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 63em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 0 0 26em;
          bottom: -6em;
          transform: rotate(30deg);
        }
        @media (max-width: $very-small-width-breakpoint) {
          display: none;
        }
      }
    }

    &__image-wrapper {
      &--1,
      &--2,
      &--3 {
        display: block;
        position: absolute;
        padding: 0.7em;
        background: white;
        border: 2px solid $default-green-color;
        width: 19em;
        height: 17.5em;
        will-change: transform;

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 0.82em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 0.75em;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.68em;
          padding: 0.5em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.72em;
          padding: 0.4em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.6em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.52em;
        }
      }

      &--1 {
        transform: translate(-26em, -2em) rotate(8deg);

        @media (max-width: $medium-width-breakpoint) {
          transform: translate(-27em, -2.7em) rotate(8deg);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          transform: translate(-8em, -2.7em) rotate(8deg);
        }
        @media (max-width: $small-width-breakpoint) {
          transform: translate(-8.5em, -2.2em) rotate(8deg);
        }
      }
      &--2 {
        transform: translate(-19em, 8.5em) rotate(-5deg);

        @media (max-width: $medium-width-breakpoint) {
          transform: translate(-20em, 7.8em) rotate(-5deg);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          transform: translate(-1em, 7.8em) rotate(-5deg);
        }
        @media (max-width: $small-width-breakpoint) {
          transform: translate(-1.5em, 8.3em) rotate(-5deg);
        }
      }
      &--3 {
        transform: translate(-9em, -3.7em) rotate(-5deg);

        @media (max-width: $medium-width-breakpoint) {
          transform: translate(-10em, -4.4em) rotate(-5deg);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          transform: translate(10em, -4.4em) rotate(-5deg);
        }
        @media (max-width: $small-width-breakpoint) {
          transform: translate(9.5em, -3.9em) rotate(-5deg);
        }
      }
    }

    &__image {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }

    &__image-wrapper--1 &__image,
    &__image-wrapper--3 &__image {
      object-position: center;
    }
    &__image-wrapper--2 &__image {
      object-position: left;
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: flex-start;
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      z-index: 2;
      max-width: $content-max-width;
      padding: 8.9em 0.8em 0;

      // @media (max-width: $large-width-breakpoint) {
      //   padding: 8.4em 0.8em 0;
      // }

      @media (max-width: $high-width-breakpoint) {
        // padding: 8em 3em 0;
        padding: 8em 1.5em 0;
      }
      @media (max-width: $medium-width-breakpoint) {
        padding: 7em 1.5em 0;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        align-items: center;
        justify-content: center;
        padding: 1.5em 0 0;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 2.2em 0 0;
      }
      @media (max-width: $very-small-width-breakpoint) {
        align-items: stretch;
        justify-content: stretch;
        padding: 0;
      }
    }

    &__content-wrapper {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 0.95em;

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 0.85em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.75em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        align-items: center;
        justify-content: center;
        padding: 0.8em 1.2em 2em;
        background: rgb(248 248 248 / 65%);
        // background: linear-gradient(to right, hsl(120deg 86% 28% / 65%), hsl(94deg 61% 41% / 65%));
        border-radius: 3px;
        box-shadow: 0px 0px 2px 1px #00a400ad;
        font-size: 0.82em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        flex: 1;
        box-shadow: none;
        font-size: 0.6em;
        padding: 4em 0 0;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.55em;
        padding: 5.5em 0 0;
      }
    }

    &__header {
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 0.035em;
      font-size: 2.92em;
      line-height: 1.1em;
      animation: slide-in $default-animation-period $default-animation-function 0.9s backwards;
      color: $main-background-color;
      text-shadow: 2px 0 0 $default-black-color, -2px 0 0 $default-black-color, 0 2px 0 $default-black-color, 0 -2px 0 $default-black-color, 2px 2px $default-black-color, -2px -2px 0 $default-black-color, 2px -2px 0 $default-black-color, -2px 2px 0 $default-black-color;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 2.5em;
      }

      &__strong-text {
        font-size: 1.2em;
        font-weight: 700;
        animation: glowing-text 7s linear both 1.5s infinite;

        // @media (max-width: $small-width-breakpoint) {
        //   font-weight: 800;
        // }
      }
    }

    &__specifier {
      font-size: 1.5em;
      text-transform: uppercase;
      font-weight: 900;
      margin: 0.1em 0 0;
      animation: slide-in $default-animation-period $default-animation-function 1.3s backwards;
      max-width: 30em;
      color: $default-green-color;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.8em;
        font-weight: 800;
        max-width: 17em;
        text-align: center;
      }
    }

    &__description-container {
      align-self: flex-end;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      animation: slide-in $default-animation-period $default-animation-function 0.9s backwards;

      @media (max-width: $very-small-width-breakpoint) {
        align-self: center;
      }
    }

    &__description {
      &__title {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 3.02em;
        font-size: 2.54em;
        color: $default-black-color;

        @media (max-width: $tiny-width-breakpoint) {
          align-self: center;
          font-size: 2.5em;
          font-size: 2.103em;
          font-weight: 800;
        }
      }

      &__list {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        list-style: none;
        margin: 1em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          width: 30.5em;
        }

        &__item {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &:not(:last-child) {
            margin: 0 0 0.2em;
          }

          &__icon {
            max-width: 1.5em;
            margin: 0 0.4em 0 0;
          }

          &__text {
            font-size: 1.5em;
            font-weight: 500;
            color: $default-black-color;

            &--highlight {
              font-size: 1.1em;
              font-weight: 800;

              @media (max-width: $tiny-width-breakpoint) {
                font-weight: 900;
              }
            }

            @media (max-width: $tiny-width-breakpoint) {
              font-weight: 600;
            }
          }
        }
      }

      &__action-button {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.4em;
        margin: 1em 0 0;
        padding: 0.3em 0.8em;
        font-weight: 600;
        border-radius: 5px;
        min-width: 35px;
        min-height: 35px;
        transition: $default-transition;
        color: white;
        background: linear-gradient(to right, #0a830a, #60a929);
        border: 1px solid #ffffff00;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        user-select: none;
        animation: pop-in $default-animation-period $default-animation-function 1.7s backwards;

        @media (max-width: $tiny-width-breakpoint) {
          width: auto;
          text-align: center;
          padding: 0.4em 1em;
          border-radius: 3px;
        }

        &__icon {
          font-size: 1.1em;
          margin: 0 0.4em 0 0;
        }

        &:hover {
          color: $default-green-color;
          background: $main-background-color;
          border-color: $default-green-color;
        }
      }
    }

    &__price-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 3em 0 0;
      right: 2em;

      @media (max-width: $medium-less-width-breakpoint) {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 8.2em;
        height: 8.2em;
        border-radius: 50%;
        transform-origin: center;
        transform: translate(25%, 30%) rotate(10deg);
        background: $main-background-color;
        margin: 0;
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        transform: translate(15%, 25%) rotate(10deg);
        font-size: 0.85em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        bottom: 1em;
        right: auto;
        transform: translate(17.5em, 0) rotate(10deg);
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.9em;
        transform: translate(11.5em, 0) rotate(10deg);
      }
    }

    &__price-decorator {
      display: block;
      position: absolute;
      width: 9em;
      margin: 0.5em 0 0 -0.5em;

      @media (max-width: $medium-less-width-breakpoint) {
        margin: 0;
      }
    }

    &__price {
      font-size: 1.4em;
      color: $default-green-color;
      font-weight: 600;
      z-index: 2;

      @media (max-width: $medium-less-width-breakpoint) {
        margin: -0.3em 0 0 0.5em;
      }

      &__multiplier {
        font-size: 0.8em;
      }

      &__value {
        font-size: 1.4em;
        font-weight: 700;
      }
    }
  }

  &__separator-section {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: $default-black-color;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      max-width: $content-max-width;
      padding: 1.5em 0.8em;
      flex: 1;

      @media (max-width: $high-width-breakpoint) {
        padding: 1.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 1.5em 0.8em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        padding: 1.1em 0.4em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.8em 0.4em;
      }
    }

    &__article {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 0.97em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 0.89em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.79em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.705em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.6em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.55em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.50em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.45em;
      }

      &__content-wrapper {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 1em 0 0;
        }
      }

      &__title {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 1.5em;
        margin: 0 0 0.2em;
        color: white;
        text-align: justify;

        @media (max-width: $very-small-width-breakpoint) {
          font-weight: 700;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-weight: 600;
        }
      }

      &__text {
        font-weight: 800;
        font-size: 1.8em;
        text-transform: uppercase;
        text-align: justify;
        color: white;

        @media (max-width: 710px) {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-around;
          border: 1px solid white;
          border-radius: 3px;
          padding: 0.4em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-weight: 700;
          font-size: 1.62em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-weight: 600;
        }

        &__group {
          &,
          &--borded {
            &:not(:last-child) {
              margin: 0 0.3em 0 0;
            }

            @media (max-width: 710px) {
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              justify-content: center;
            }
          }

          &--borded {
            // @media (max-width: 710px) {
            //   border: 1px solid white;
            //   border-radius: 3px;
            //   padding: 0.2em;
            // }
          }
        }

        &__subgroup {
          &:not(:last-child),
          &--underlined:not(:last-child) {
            margin: 0 0.3em 0 0;

            @media (max-width: 710px) {
              margin: 0;
            }
          }

          &--underlined {
            text-decoration: underline;
            text-align: center;
          }
        }
      }
    }

    &__action-button {
      align-self: stretch;
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.4em;
      padding: 0.3em 0.8em;
      font-weight: 800;
      border: none;
      border-radius: 8px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;
      color: $default-green-color;
      border: 4px dashed $default-green-color;
      background: $default-black-color;
      // animation: pop-in $default-animation-period $default-animation-function 1.7s backwards;

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 0.2em 0.6em;
        border-width: 3px;
      }
      @media (max-width: 755px) {
        max-width: 8.5em;
      }
      @media (max-width: 710px) {
        color: white;
        background: linear-gradient(to right, #0a830a, #60a929);
        border: 2px solid $default-black-color;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-weight: 700;
      }

      &:hover {
        color: white;
        background: linear-gradient(to right, #0a830a, #60a929);
        border: 4px solid $default-black-color;
        transform: scale(1.1, 1.1);

        @media (max-width: $medium-less-width-breakpoint) {
          border-width: 3px;
        }

        @media (max-width: 710px) {
          color: $default-green-color;
          border: 2px dashed $default-green-color;
          background: $default-black-color;
        }
      }
    }
  }

  &__about-section {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    background: $main-background-darker-color;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      z-index: 2;
      max-width: $content-max-width;
      padding: 2.5em 0.8em 2em;
      align-self: stretch;

      @media (max-width: $high-width-breakpoint) {
        padding: 2.5em 1.5em 2em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 2em 1.5em 2em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        padding: 1.5em 1.5em 2em;
      }
      @media (max-width: $small-width-breakpoint) {
        padding: 1em 0.8em 1em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.8em 0.4em 0.8em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        padding: 0.6em 0.4em 0.6em;
      }
    }

    &__images-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }

    &__images-container,
    &__content {
      min-height: 100px;
      height: 55em;

      @media (max-width: $extra-high-width-breakpoint) {
        height: 52em;
      }
      @media (max-width: $high-width-breakpoint) {
        height: 49.5em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        height: 45em;
      }
      @media (max-width: $medium-width-breakpoint) {
        height: 54em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        height: 59.5em;
      }
      @media (max-width: 760px) {
        height: 58em;
      }
      @media (max-width: 720px) {
        height: 56.5em;
      }
      @media (max-width: 635px) {
        height: 58em;
      }
      @media (max-width: $small-width-breakpoint) {
        height: 62em;
      }
      @media (max-width: 560px) {
        height: 63em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        height: 62em;
      }
      @media (max-width: 420px) {
        height: 64em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        height: 60em;
      }
      @media (max-width: 340px) {
        height: 61em;
      }
    }

    &__header {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 58.5em;

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 0.95em;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.8em;
      }
      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.75em;
        width: 65em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        width: auto;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.7em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.65em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.6em;
      }
    }

    &__title {
      font-size: 3em;
      z-index: 2;
      font-weight: 900;
      color: $default-green-color;
      line-height: 0.8em;

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 2.8em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 2.37em;
      }

      &--small {
        color: $default-black-color;
        font-size: 0.51em;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.55em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.7em;
        }
      }
    }

    &__description {
      color: $default-black-color;
      font-weight: 600;
      font-size: 1.3em;
      text-align: justify;
      margin: 0.6em 0 0;

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.35em;
      }
    }

    &__steps {
      align-self: flex-end;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 4em 0 0;
      list-style: none;

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 0.95em;
        margin: 3.5em 2em 0 0;
      }
      @media (max-width: $high-width-breakpoint) {
        font-size: 0.9em;
        margin: 3.5em 1.2em 0 0;
      }
      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 0.85em;
        margin: 3em 1em 0 0;
      }
      @media (max-width: $medium-width-breakpoint) {
        align-self: stretch;
        align-items: stretch;
        font-size: 0.95em;
        margin: 16.5em 0 0;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1em;
        padding: 0 2em 0;
      }
      @media (max-width: 810px) {
        padding: 0 0.6em 0;
      }
      @media (max-width: 760px) {
        font-size: 0.95em;
      }
      @media (max-width: 720px) {
        font-size: 0.85em;
      }
      @media (max-width: 635px) {
        padding: 0;
      }
      @media (max-width: $small-width-breakpoint) {
        margin: 13.5em 0 0;
        padding: 0 0.8em 0;
      }
      @media (max-width: $very-small-width-breakpoint) {
        margin: 13em 0 0;
      }
      @media (max-width: 420px) {
        margin: 11em 0 0;
        padding: 0 0.7em 0;
      }
      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.8em;
        margin: 11.7em 0 0;
      }
      @media (max-width: 340px) {
        margin: 11.4em 0 0;
      }
      @media (max-width: 320px) {
        padding: 0 0.4em 0;
      }
    }

    &__step-row {
      &--1,
      &--2,
      &--3 {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        height: 8.5em;

        &:not(:last-child) {
          margin: 0 0 2em;

          @media (max-width: $medium-width-breakpoint) {
            margin: 0 0 1em;
          }
          @media (max-width: $medium-less-width-breakpoint) {
            margin: 0;
          }
        }

        @media (max-width: $medium-width-breakpoint) {
          justify-content: space-around;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          flex-flow: column nowrap;
          justify-content: flex-start;
          height: auto;
        }
      }

      &--2 {
        right: 2.5em;

        @media (max-width: $medium-width-breakpoint) {
          right: auto;
          padding: 0 2.5em 0;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          padding: 0;
        }
      }
      &--3 {
        right: 5em;

        @media (max-width: $medium-width-breakpoint) {
          right: auto;
          padding: 0 5em 0;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          padding: 0;
        }
      }
    }

    &__step {
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6 {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        font-size: 0.9em;

        &:not(:last-child) {
          margin: 0 2em 0 0;

          @media (max-width: $medium-width-breakpoint) {
            margin: 0 3em 0 0;
          }
          @media (max-width: $tiny-width-breakpoint) {
            margin: 0;
          }
        }

        @media (max-width: $medium-less-width-breakpoint) {
          height: 5em;
        }
        @media (max-width: 720px) {
          height: 6em;
        }
        @media (max-width: 635px) {
          height: 6.3em;
        }
        @media (max-width: $small-width-breakpoint) {
          height: 8.5em;
        }
        @media (max-width: 420px) {
          height: 9.3em;
        }
      }

      &--2,
      &--6 {
        top: 4.5em;

        @media (max-width: $medium-width-breakpoint) {
          align-items: flex-end;
          top: auto;
          margin-left: 3em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          align-self: flex-end;
        }
        @media (max-width: $tiny-width-breakpoint) {
          margin-left: 0;
        }
      }

      &--4 {
        top: 2.8em;

        @media (max-width: $medium-width-breakpoint) {
          top: auto;
          align-items: flex-end;
          margin-left: 3em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          align-self: flex-end;
        }
        @media (max-width: $tiny-width-breakpoint) {
          margin-left: 0;
        }
      }

      &__header,
      &__content {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        position: relative;
      }

      &__header {
        position: relative;
        align-items: flex-end;
        left: 0.5em;
        z-index: 2;

        &__title {
          text-align: right;
          font-size: 1.4em;
          font-weight: 900;
          color: $main-background-color;
          text-shadow: 2px 0 0 $default-black-color, -2px 0 0 $default-black-color, 0 2px 0 $default-black-color, 0 -2px 0 $default-black-color, 2px 2px $default-black-color, -2px -2px 0 $default-black-color, 2px -2px 0 $default-black-color, -2px 2px 0 $default-black-color;
          text-transform: uppercase;
          margin: 0 0 0.4em;
          line-height: 1em;
          z-index: 2;

          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 1.3em;
          }
          @media (max-width: 320px) {
            font-size: 1.1em;
            font-weight: 800;
            margin: 0 0 0.5em;
          }
        }

        &__subtitle {
          position: relative;
          top: 0.2em;
          font-size: 1.25em;
          font-weight: 900;
          color: $default-black-color;
          text-transform: uppercase;
          margin: 0;
          z-index: 2;

          @media (max-width: $medium-less-width-breakpoint) {
            font-size: 1.15em;
          }
        }
      }

      &--2 &__header,
      &--4 &__header,
      &--6 &__header {
        &__title {
          @media (max-width: $medium-width-breakpoint) {
            order: 2;
            text-align: left;
          }
        }
      }

      &--1 &__header {
        left: 1.6em;

        @media (max-width: $medium-less-width-breakpoint) {
          left: 2em;
        }
        @media (max-width: 320px) {
          left: 2.5em;
        }
      }
      &--1 &__header__subtitle {
        left: 3em;

        @media (max-width: $medium-less-width-breakpoint) {
          left: 2.9em;
        }
      }

      &--2 &__header {
        left: 1.6em;

        @media (max-width: $medium-width-breakpoint) {
          left: auto;
          right: 1.6em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          right: 2em;
        }
        @media (max-width: 320px) {
          right: 2.6em;
        }
      }
      &--2 &__header__subtitle {
        left: 3em;

        @media (max-width: $medium-width-breakpoint) {
          left: auto;
          right: 3em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          right: 2.9em;
        }
      }

      &--3 &__header {
        left: 1.6em;

        @media (max-width: $medium-less-width-breakpoint) {
          left: 2em;
        }
        @media (max-width: 320px) {
          left: 2.5em;
        }
      }
      &--3 &__header__subtitle {
        left: 3em;

        @media (max-width: $medium-less-width-breakpoint) {
          left: 2.9em;
        }
      }

      &--4 &__header {
        left: 1.4em;

        @media (max-width: $medium-width-breakpoint) {
          left: auto;
          right: -1.5em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          right: -0.8em;
        }
        @media (max-width: 320px) {
          right: 0.2em;
        }
      }
      &--4 &__header__subtitle {
        left: 3em;

        @media (max-width: $medium-width-breakpoint) {
          left: auto;
          right: 3em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          right: 2.9em;
        }
      }
      &--4 &__image {
        object-position: left;
      }

      &--5 &__header {
        left: 1.6em;

        @media (max-width: $medium-less-width-breakpoint) {
          left: 2em;
        }
        @media (max-width: 320px) {
          left: 2.5em;
        }
      }
      &--5 &__header__subtitle {
        left: 3em;

        @media (max-width: $medium-less-width-breakpoint) {
          left: 2.9em;
        }
      }
      &--5 &__image {
        object-position: left;
      }

      &--6 &__header {
        left: 1.6em;

        @media (max-width: $medium-width-breakpoint) {
          left: auto;
          right: 1.6em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          right: 2em;
        }
        @media (max-width: 320px) {
          right: 2.6em;
        }
      }
      &--6 &__header__subtitle {
        left: 3em;

        @media (max-width: $medium-width-breakpoint) {
          left: auto;
          right: 3em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          right: 2.9em;
        }
      }

      &__content {
        align-items: center;

        @media (max-width: $medium-width-breakpoint) {
          justify-content: flex-start;
        }
      }

      &__image-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 7em;
        width: 1.155 * 7em;
        font-size: 1em;
        z-index: 1;
      }

      &--2 &__image-wrapper,
      &--4 &__image-wrapper,
      &--6 &__image-wrapper {
        @media (max-width: $medium-width-breakpoint) {
          order: 2;
        }
      }

      &__image-detail {
        position: absolute;
        display: block;
        object-fit: contain;
        width: 205%;
        height: 205%;
        transform-origin: center;
        // transform: scale(1.15);
        will-change: transform;
        transition: $default-animation-period transform;
      }

      &__image {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
        clip-path: polygon(25% 0,75% 0%,100% 50%,75% 100%,25% 100%,0 50%);
      }

      &__icon {
        display: block;
        position: absolute;
        object-fit: contain;
        max-width: 3em;
        top: -1.5em;
        right: 0;
      }

      &--2 &__icon,
      &--4 &__icon,
      &--6 &__icon {
        @media (max-width: $medium-width-breakpoint) {
          right: auto;
          left: 0;
        }
      }

      &__description {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        position: relative;

        &__background {
          position: absolute;
          display: block;
          object-fit: contain;
          left: -7em;
          width: 24em;
        }

        &__text {
          font-size: 0.82em;
          z-index: 2;
          text-align: justify;
          max-width: 14em;
          margin: 0.5em 0 0 1.1em;

          @media (max-width: $medium-plus-width-breakpoint) {
            font-weight: 500;
            max-width: 14.2em;
            margin: 0.5em 0 0 1em;
          }
        }
      }

      &--2 &__description,
      &--4 &__description,
      &--6 &__description {
        &__background {
          @media (max-width: $medium-width-breakpoint) {
            transform: scaleX(-1);
            left: auto;
            right: -7em;
          }
        }

        &__text {
          @media (max-width: $medium-width-breakpoint) {
            margin: 0.5em 1em 0 0;
          }
        }
      }
    }

    &__image-wrapper {
      &--1,
      &--2 {
        display: block;
        position: absolute;
        background: white;
        border: 1px solid $default-black-color;
        will-change: transform;

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 0.95em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 0.8em;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.75em;
        }
        @media (max-width: 810px) {
          font-size: 0.7em;
        }
        @media (max-width: 720px) {
          font-size: 0.63em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.54em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.49em;
        }
        @media (max-width: 420px) {
          font-size: 0.435em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.4em;
        }
        @media (max-width: 320px) {
          font-size: 0.4em;
        }
      }

      &--1 {
        width: 24em;
        height: 18em;
        transform: translate(-26em, 0em);

        @media (max-width: $extra-high-width-breakpoint) {
          transform: translate(-25em, -0.5em);
        }
        @media (max-width: $high-width-breakpoint) {
          transform: translate(-20.5em, -0.5em);
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          transform: translate(-22.25em, -0.5em);
        }
        @media (max-width: $medium-width-breakpoint) {
          transform: translate(-14em, -12em);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          transform: translate(-14em, -15.3em);
        }
        @media (max-width: 810px) {
          transform: translate(-14em, -16em);
        }
        @media (max-width: 720px) {
          transform: translate(-14em, -17em);
        }
        @media (max-width: $small-width-breakpoint) {
          transform: translate(-15em, -29.8em);
        }
        @media (max-width: 560px) {
          transform: translate(-12.7em, -28em);
        }
        @media (max-width: $very-small-width-breakpoint) {
          transform: translate(-12.7em, -32em);
        }
        @media (max-width: 420px) {
          transform: translate(-12.7em, -39em);
        }
        @media (max-width: $tiny-width-breakpoint) {
          width: 20em;
          transform: translate(-11.7em, -40em);
        }
        @media (max-width: 340px) {
          transform: translate(-11.7em, -39em);
        }
        @media (max-width: 320px) {
          transform: translate(-10.8em, -38.5em);
        }
      }
      &--2 {
        width: 22em;
        height: 16em;
        transform: translate(-22em, 11em);

        @media (max-width: $extra-high-width-breakpoint) {
          transform: translate(-21em, 10.5em);
        }
        @media (max-width: $high-width-breakpoint) {
          transform: translate(-23.5em, 11.5em);
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          transform: translate(-24.75em, 12em);
        }
        @media (max-width: $medium-width-breakpoint) {
          width: 24em;
          height: 18em;
          transform: translate(14em, -12em);
        }
        @media (max-width: $medium-less-width-breakpoint) {
          transform: translate(14em, -15.3em);
        }
        @media (max-width: 810px) {
          transform: translate(14em, -16em);
        }
        @media (max-width: 720px) {
          transform: translate(14em, -17em);
        }
        @media (max-width: $small-width-breakpoint) {
          transform: translate(15em, -29.8em);
        }
        @media (max-width: 560px) {
          transform: translate(12.7em, -28em);
        }
        @media (max-width: $very-small-width-breakpoint) {
          transform: translate(12.7em, -32em);
        }
        @media (max-width: 420px) {
          transform: translate(12.7em, -39em);
        }
        @media (max-width: $tiny-width-breakpoint) {
          width: 20em;
          transform: translate(11.7em, -40em);
        }
        @media (max-width: 340px) {
          transform: translate(11.7em, -39em);
        }
        @media (max-width: 320px) {
          transform: translate(10.8em, -38.5em);
        }
      }
    }

    &__image {
      object-fit: cover;
      max-width: 100%;
      height: 100%;
    }

    &__image-wrapper--1 &__image {
      object-position: right;
    }
    &__image-wrapper--2 &__image {
      object-position: center;
    }

    &__background-detail {
      &--1,
      &--2 {
        display: block;
        position: absolute;
        background: $main-background-color;
        border-radius: 50%;
        transform-origin: center center;
      }

      &--1 {
        // height: 120em;
        // width: 130em;
        height: 218%;
        width: 125%;
        left: 0;
        top: 0;
        transform: translate(-60%, -57%);
      }
      &--2 {
        // height: 130em;
        // width: 80em;
        height: 235%;
        width: 76%;
        right: 0;
        bottom: 0;
        transform: translate(80%, 57%);
      }
    }

    &__decoration-image {
      &--1 {
        display: block;
        position: absolute;
        object-fit: contain;

        @media (max-width: $extra-high-width-breakpoint) {
          font-size: 0.95em;
        }
        @media (max-width: $high-width-breakpoint) {
          font-size: 0.9em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          font-size: 0.85em;
        }
        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.8em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.75em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.7em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          font-size: 0.65em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.6em;
        }
      }

      &--1 {
        width: 27em;
        margin: 0 0 0 76em;
        bottom: 1em;

        @media (max-width: $extra-high-width-breakpoint) {
          margin: 0 0 0 65em;
          bottom: -1em;
        }
        @media (max-width: $high-width-breakpoint) {
          margin: 0 0 0 57em;
        }
        @media (max-width: $medium-plus-width-breakpoint) {
          margin: 0 0 0 54em;
        }
        @media (max-width: $medium-width-breakpoint) {
          margin: 0 0 0 50em;
          bottom: -2em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 0 0 45em;
        }
        @media (max-width: 635px) {
          margin: 0 0 0 40em;
        }
        @media (max-width: $small-width-breakpoint) {
          margin: 0 0 0 34em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          margin: 0 0 0 28em;
        }
        @media (max-width: $tiny-width-breakpoint) {
          margin: 0 0 0 15em;
        }
      }
    }
  }

  &__video-section {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    margin: 0;

    // @media (max-width: 1030px) {
    //   margin: 5em 0 0;
    // }
    //
    // @media (max-width: 910px) {
    //   margin: 8em 0 0;
    // }
    //
    // @media (max-width: 750px) {
    //   margin: 4.3em 0 0;
    // }
    //
    // @media (max-width: 490px) {
    //   margin: 3.7em 0 0;
    // }

    &__images-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }

    &__background-filter {
      display: block;
      position: absolute;
      object-fit: cover;
      width: 100%;
      opacity: 0.93;
      height: 100%;
      background: linear-gradient(to right, #0a830a, #60a929);
    }

    &__main-image {
      object-fit: cover;
      object-position: center 10%;
      width: 100%;
    }

    &__images-container,
    &__background-filter,
    &__main-image,
    &__content {
      min-height: 100px;
      height: 30em;

      // @media (max-width: $large-width-breakpoint) {
      //   height: 37.44em;
      // }

      @media (max-width: $high-width-breakpoint) {
        height: 27em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        height: 24em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        height: 21em;
      }

      @media (max-width: $small-width-breakpoint) {
        height: 19em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        height: 17em;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      z-index: 2;
      max-width: $content-max-width;
      padding: 4em 0.8em 0;
      color: white;

      @media (max-width: $extra-high-width-breakpoint) {
        font-size: 0.95em;
      }

      @media (max-width: $high-width-breakpoint) {
        padding: 3.5em 0.8em 0;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 3em 0.8em 0;
      }

      @media (max-width: $medium-width-breakpoint) {
        align-items: center;
        justify-content: center;
        padding: 0 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0 0.4em;
      }
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 8em;

      @media (max-width: $high-width-breakpoint) {
        font-size: 6.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 5em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 4.5em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 4em;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 3.5em;
      }
    }

    &__header {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.1em;

      // @media (max-width: $large-width-breakpoint) {
      //   font-size: 3.5em;
      // }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.3em;
        font-weight: 800;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 1.1em;
      }
    }

    &__specifier {
      font-size: 1.9em;
      font-weight: 200;
      margin: 0.5em 0 0;
      max-width: 30em;
      text-align: center;

      // @media (max-width: $large-width-breakpoint) {
      //   font-size: 1.7em;
      // }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.5em;
        font-weight: 300;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        font-weight: 400;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1em;
        text-align: justify;
      }

      // @media (max-width: $tiny-width-breakpoint) {
      //   font-size: 0.8em;
      // }
    }

    &__trial-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 1.5em 0 0;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1.3em;
      padding: 0.4em 0;
      font-weight: 500;
      width: 12em;
      border-radius: 5px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;
      color: $default-black-color;
      background: white;
      border: 2px solid white;

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.1em;
      }
      @media (max-width: $small-width-breakpoint) {
        border-radius: 3px;
      }
      @media (max-width: $very-small-width-breakpoint) {
        margin: 1em 0 0;
      }
      @media (max-width: $tiny-width-breakpoint) {
        margin: 0.5em 0 0;
      }

      &:hover {
        color: white;
        background: $default-black-color;
        border: 1px solid $default-black-color;
      }

      &__icon {
        font-size: 1.4em;
        margin: 0 0.4em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          position: absolute;
          left: 1em;
        }
      }
    }
  }

  &__contact-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: $main-background-color;
    position: relative;

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      max-width: $content-max-width;
      padding: 5em 0.8em 5em;
      overflow: hidden;
      max-width: 75em;
      width: 100%;
      z-index: 2;

      @media (max-width: $extra-high-width-breakpoint) {
        padding: 4.5em 1.8em 3.5em;
      }

      @media (max-width: $high-width-breakpoint) {
        padding: 4em 1.8em 3em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        padding: 3.5em 1.2em 2.5em;
      }

      @media (max-width: $medium-width-breakpoint) {
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: 2.5em 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        padding: 2.5em 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 1.5em 0.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 1.1em 0.4em;
      }
    }

    &__title {
      font-size: 3.1em;
      text-transform: uppercase;
      font-weight: 500;
      color: $default-black-color;

      &--highlighted {
        color: $default-green-color;
        font-weight: 700;
      }

      // @media (max-width: $large-width-breakpoint) {
      //   font-size: 1.8em;
      // }

      @media (max-width: $high-width-breakpoint) {
        font-size: 2.7em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 2.5em;
      }

      // @media (max-width: $medium-width-breakpoint) {
      //   font-size: 1.65em;
      // }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $small-width-breakpoint) {
        text-align: center;
        font-size: 1.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.6em;
        font-weight: 600;
      }

      // @media (max-width: $tiny-width-breakpoint) {
      //   font-size: 1.25em;
      // }
    }

    &__hr {
      display: block;
      height: 4px;
      width: 17em;
      background: linear-gradient(to right, #0a830a, #60a929);
      border: none;

      @media (max-width: $medium-less-width-breakpoint) {
        height: 3px;
      }
    }

    &__semi-title {
      font-size: 1.6em;
      margin: 0.2em 0 0.8em;
      font-weight: 400;
      color: $default-black-color;
      text-align: center;
      max-width: 32em;

      // @media (max-width: $large-width-breakpoint) {
      //   font-size: 1.45em;
      // }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $medium-plus-width-breakpoint) {
        font-size: 1.3em;
      }

      // @media (max-width: $medium-width-breakpoint) {
      //   font-size: 1.35em;
      //   border-width: 0;
      //   padding: 0;
      // }

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 1.16em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1em;
        font-weight: 500;
        text-align: justify;
      }

      // @media (max-width: $small-width-breakpoint) {
      //   font-size: 1.15em;
      // }
      //
      // @media (max-width: $very-small-width-breakpoint) {
      //   border-width: 0 4px;
      //   padding: 0 0.4em;
      //   font-size: 1.05em;
      // }
      //
      // @media (max-width: $tiny-width-breakpoint) {
      //   border-width: 0 2px;
      // }
    }

    &__form-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 48.5em;

      @media (max-width: $medium-width-breakpoint) {
        width: 100%;
      }
    }

    &__input-label {
      color: $default-black-color;
      margin: 0 0 0.2em;
    }

    &__input {
      & input,
      & select,
      & textarea {
        border: 1px solid $default-black-color;
        border-radius: 5px;
        font-size: 1.3em;
        color: $default-black-color;

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.15em;
        }

        &:focus {
          border-color: $default-green-color;
        }
      }

      & .default-input__input--highlighted,
      & .default-input__select-input--highlighted  {
        border-color: $site-red-color;

        &:focus {
          box-shadow: none;
        }
      }
    }

    &__send-form-button {
      cursor: pointer;

      &,
      &--active {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin: 1em 0 0;
        text-decoration: none;
        text-transform: uppercase;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        user-select: none;
        font-size: 1.3em;
        padding: 0.4em 0;
        font-weight: 500;
        width: 15em;
        border-radius: 5px;
        min-width: 35px;
        min-height: 35px;
        transition: $default-transition;
        color: white;
        background: linear-gradient(to right, #0a830a, #60a929);
        border: 1px solid $main-background-color;

        @media (max-width: $very-small-width-breakpoint) {
          width: 100%;
        }
      }

      &--active {
        cursor: default;
      }

      &__icon {
        font-size: 1.4em;
        margin: 0 0.4em 0 0;

        @media (max-width: $tiny-width-breakpoint) {
          position: absolute;
          left: 1em;
        }
      }

      &--active,
      &:hover {
        color: $default-green-color;
        background: $main-background-color;
        border-color: $default-green-color;
      }

      // @media (max-width: $large-width-breakpoint) {
      //   font-size: 1.3em;
      // }

      @media (max-width: $high-width-breakpoint) {
        font-size: 1.1em;
      }

      // @media (max-width: $small-width-breakpoint) {
      //   font-size: 1em;
      // }
      //
      // @media (max-width: $very-small-width-breakpoint) {
      //   font-size: 0.9em;
      // }
      //
      // @media (max-width: $tiny-width-breakpoint) {
      //   width: auto;
      //   text-align: center;
      //   padding: 0.4em 1em;
      // }
    }

    &__warning-container {
      margin: 0 0 0.6em;

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 0 0 0.4em;
      }
    }

    &__images-container {
      position: absolute;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &__background-detail {
      &--1,
      &--2 {
        display: block;
        position: absolute;
        background: $main-background-darker-color;
        border-radius: 50%;
        transform-origin: center center;
      }

      &--1 {
        height: 100em;
        width: 80em;
        left: 0;
        bottom: 0;
        transform: translate(-80%, 50%);
      }
      &--2 {
        height: 125em;
        width: 41em;
        right: 0;
        top: 0;
        transform: translate(60%, -70%);
      }
    }

    &__discount-field {
      &__container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.4em 0.4em 0;
        border: 2px dashed $default-green-color;
        border-radius: 4px;
        margin: 1em 0 0.6em;
        min-height: 6em;
        overflow: hidden;
        position: relative;
      }

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;

        @media (max-width: 420px) {
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
        }
      }

      &__input {
        flex: 1;

        & input,
        & select,
        & textarea {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          @media (max-width: 420px) {
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0;
          }
        }
      }

      &__apply-button {
        font-family: 'Open Sans', sans-serif;
        padding: 1.07em 0.8em;
        outline: none;
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        border-radius: 0 3px 3px 0;
        user-select: none;
        font-size: 0.9em;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;
        background: linear-gradient(to right, #0a830a, #60a929);
        color: white;
        text-transform: uppercase;

        &:hover:not(:disabled) {
          background: #5bd65b;
        }

        &:disabled {
          background: #dcdcdc;
          color: $default-gray-color;
          cursor: default;
        }

        @media (max-width: 1100px) {
          padding: 0.93em 0.8em;
        }
        @media (max-width: 680px) {
          padding: 0.85em 0.8em;
        }
        @media (max-width: $small-width-breakpoint) {
          padding: 0.75em 0.8em;
        }
        @media (max-width: $very-small-width-breakpoint) {
          padding: 0.7em 0.8em;
        }
        @media (max-width: 420px) {
          border-radius: 0 0 3px 3px;
        }
      }

      &__result-message {
        margin: 0.7em 0 0.5em;
      }

      &__loading-icon {
        position: absolute;
        font-size: 0.55em;
        margin: 0.5em 0;
        width: 100%;
      }
    }
  }

  &__alert-text {
    &,
    &--green {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      background: #f5f5f5;
      border-radius: 5px;
      font-weight: bold;
      margin: 0.5em 0;
      animation: slide-in $default-animation-period $default-animation-function backwards;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
        border-radius: 3px;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.7em;
        border-width: 1px;
      }
    }

    & {
      color: #ea5460;
      border: 2px solid #ea5460;
      border-left-width: 0;
    }
    &--green {
      color: #6aaf38;
      border: 2px solid #6aaf38;
      border-left-width: 0;
    }

    &__text {
      text-align: justify;
      flex: 1;
      margin: 0.5em;
    }

    &__link {
      color: #0077a4;
    }

    &__icon-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
    }

    & &__icon-wrapper {
      background: #ea5460;
    }
    &--green &__icon-wrapper {
      background: #6aaf38;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.3em;
    }

    & &__icon {
      color: #ea5460;
    }
    &--green &__icon {
      color: #6aaf38;
    }
  }
}
