@import '../color_variables.scss';
@import '../size_variables';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

$content-max-width: 75em;

$background-dark-color: #212121;
$default-green-color: #00a400;

$default-animation-period: 0.6s;
$default-animation-function: cubic-bezier(0.17, 0.67, 0.31, 1.78);

$default-transition: all 0.3s cubic-bezier(0.22, 0.97, 0.58, 1);

@keyframes slide-in {
  from {
    opacity: 0;
    transform-origin: -30% center;
    transform: rotate(5deg) translateX(-20%);
  }

  to {
    opacity: 1;
    transform-origin: -30% center;
    transform: rotate(0) translateX(0);
  }
}


.create-contract {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 6em 0 0;
  // background: #212930;
  background: radial-gradient(#353535, $background-dark-color);
  flex: 1;
  min-height: 10em;

  @media (max-width: 1030px) {
    margin: 5em 0 0;
  }

  @media (max-width: 910px) {
    // margin: 8em 0 0;
    margin: 3.8em 0 0;
  }

  // @media (max-width: 750px) {
  //   margin: 4.3em 0 0;
  // }

  @media (max-width: 490px) {
    margin: 3.4em 0 0;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    z-index: 2;
    max-width: $content-max-width;
    padding: 3.5em 0.8em 2.5em;
    color: white;
    flex: 1;

    @media (max-width: $large-width-breakpoint) {
      padding: 3.5em 0.8em 2.5em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      padding: 3em 1.5em 2.5em;
    }
    @media (max-width: $high-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      padding: 2.5em 1.2em;
    }
    @media (max-width: $medium-width-breakpoint) {
      padding: 2.5em 1.5em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      padding: 2.5em 1em;
    }
    @media (max-width: $small-width-breakpoint) {
      padding: 1.5em 0.8em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      padding: 1.2em 0.8em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      padding: 1.2em 0.4em;
    }
  }

  &__title {
    font-size: 2.2em;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 0.75em;

    @media (max-width: $large-width-breakpoint) {
      font-size: 2.1em;
    }
    @media (max-width: $extra-high-width-breakpoint) {
      font-size: 2em;
    }
    @media (max-width: $high-width-breakpoint) {
      font-size: 1.8em;
    }
    @media (max-width: $medium-plus-width-breakpoint) {
      font-size: 1.7em;
    }
    @media (max-width: $medium-width-breakpoint) {
      margin: 0 0 0.5em;
      align-self: center;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.6em;
    }
    @media (max-width: $small-width-breakpoint) {
      font-size: 1.45em;
    }
    @media (max-width: $very-small-width-breakpoint) {
      font-size: 1.3em;
    }
    @media (max-width: $tiny-width-breakpoint) {
      font-size: 1.2em;
      text-align: center;
    }

    &--green {
      color: $default-green-color;
    }
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;
  }

  &__alert-text {
    &,
    &--green {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      background: #f5f5f5;
      border-radius: 5px;
      font-weight: bold;
      margin: 0.5em 0;
      animation: slide-in $default-animation-period $default-animation-function backwards;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
        border-radius: 3px;
      }
      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.7em;
        border-width: 1px;
      }
    }

    & {
      color: #ea5460;
      border: 2px solid #ea5460;
      border-left-width: 0;
    }
    &--green {
      color: #6aaf38;
      border: 2px solid #6aaf38;
      border-left-width: 0;
    }

    &__text {
      text-align: justify;
      flex: 1;
      margin: 0.5em;
    }

    &__link {
      color: #0077a4;
    }

    &__icon-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;
    }

    & &__icon-wrapper {
      background: #ea5460;
    }
    &--green &__icon-wrapper {
      background: #6aaf38;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0 0.3em;
    }

    & &__icon {
      color: #ea5460;
    }
    &--green &__icon {
      color: #6aaf38;
    }
  }

  &__link-button {
    cursor: pointer;

    &,
    &--active {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 1em 0 0;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      font-size: 1.3em;
      padding: 0.5em 1em;
      font-weight: 600;
      border-radius: 5px;
      min-width: 35px;
      min-height: 35px;
      transition: $default-transition;
      color: white;
      background: linear-gradient(to right, #0a830a, #60a929);
      border: none;

      @media (max-width: $very-small-width-breakpoint) {
        width: 100%;
      }
    }

    &--active {
      cursor: default;
    }

    &__icon {
      font-size: 1.4em;
      margin: 0 0.4em 0 0;

      @media (max-width: $tiny-width-breakpoint) {
        position: absolute;
        left: 1em;
      }
    }

    &--active,
    &:hover {
      color: $default-green-color;
      background: #f8f8f8;
      // border-color: $default-green-color;
    }

    @media (max-width: $high-width-breakpoint) {
      font-size: 1.1em;
    }
  }
}
